import { useMemo } from 'react';
import { Typography, Grid, IconButton, Box } from 'nxg-ui-wrapper';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import DropdownMenu from '../DropdownMenu';
import styles from './Header.module.css';
import { TextCommon, TestCommon } from 'src/common';
import { TypeContext } from '../../store';
import { useAuthenticated } from '../../hooks/context';
import useHeader from './hook/useHeader';
import { IC_LOGOUT, IC_MENU } from '../../assets';
import { pages } from 'src/global/pages';
import { isDevelopMode } from 'src/common/base.common';

const BACKDOOR_OPENING_POSITION = 7;
const Header = (): JSX.Element => {
    const [appState]: TypeContext = useAuthenticated();
    const {
        handleSignOut,
        handleOpenBackdoor,
        handleIsShowMenu,
        isShowMenu,
        handleSetIsShowLabel,
        handleSetIsHideLabel,
        isUploading,
        isShowLabel,
        handleForceRemove,
    } = useHeader();

    const renderForceRemoveBlocking = (): JSX.Element => {
        const isExistedFileInS3: boolean = useMemo(() => {
            return appState.s3File.totalSize > 0;
        }, [appState.s3File]);

        if (appState.isOtherProcessing && !isExistedFileInS3) {
            return (
                <IconButton color="error" onClick={handleForceRemove} sx={{ mr: 1 }}>
                    <DeleteSweepOutlinedIcon />
                </IconButton>
            );
        }

        return <></>;
    };

    const renderTitle = (): JSX.Element => {
        const stringArray: (string | JSX.Element)[] = [];
        const title = TextCommon.HEADER_TITLE;
        for (let i = 0; i < title.length; i++) {
            if (i === BACKDOOR_OPENING_POSITION && isDevelopMode()) {
                stringArray.push(
                    <span
                        key={i}
                        className={styles['backdoor-character']}
                        data-testid={TestCommon.BACKDOOR_POSITION_DATA_TEST_ID}
                        onClick={handleOpenBackdoor}
                    >
                        {title[i]}
                    </span>,
                );
            } else {
                stringArray.push(title[i]);
            }
        }
        return (
            <Typography className={styles['header-title']} data-testid={TestCommon.HEADER_TITLE_DATA_TEST_ID}>
                {stringArray.map(element => element)}
            </Typography>
        );
    };

    return (
        <Grid className={styles.fluid} data-testid={TestCommon.HEADER_DATA_TEST_ID}>
            <Grid className={styles.wrapper}>
                <Grid container className={styles.wrapper__container}>
                    <Grid flexGrow={1}>
                        <Grid container alignItems={'center'} className={styles['menu-wrapper']}>
                            <IconButton onClick={handleIsShowMenu} data-testid={TestCommon.MENU_ICON_DATA_TEST_ID}>
                                <img height={19} width={19} src={IC_MENU} alt={TextCommon.BT_MENU} />
                            </IconButton>
                            {renderTitle()}
                            {isShowMenu && (
                                <Box className={styles['dropdown-menu']}>
                                    <DropdownMenu pagesData={pages} />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <Grid>
                        {renderForceRemoveBlocking()}
                        <IconButton
                            onClick={handleSignOut}
                            disabled={isUploading}
                            onMouseOver={handleSetIsShowLabel}
                            onMouseOut={handleSetIsHideLabel}
                            data-testid={TestCommon.LOGOUT_ICON_DATA_TEST_ID}
                        >
                            <img height={26} width={26} src={IC_LOGOUT} alt={TextCommon.BT_LOGOUT} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {isShowLabel && <Box className={styles['signout-hover']}>{TextCommon.SIGNOUT}</Box>}
        </Grid>
    );
};

export default Header;
