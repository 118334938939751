import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
    ICreatedMachineFromApi,
    ICreatedMachineForApi,
    IDeletedMachineFromApi,
    IMachineItem,
    IMachineListFromApi,
    IModelListFromApi,
    IUpdatedMachineFromApi,
    IUpdatedMachineForApi,
    IMachineAction,
    IMachineLogInfomation,
    IDeletedMachineLogFromApi,
    IDeleteMachineLogItem,
} from 'src/types';
import { MachineUtils } from 'src/utils';
import { BaseCommon, MachineCommon, MessageCommon, TypeCommon, TestCommon } from 'src/common';
import { useAuthenticated } from 'src/hooks/context';
import format from 'string-format';
import { IC_DELETE, IC_EDIT, IC_LOG, IC_LOG_INACTIVE } from 'src/assets';
import { MachineManagementStyle as styles } from '../MachineManagementStyle';
import { ITableRow } from 'nxg-ui-wrapper';
import { CURSOR_POINTER, EMPTY_STRING, ZERO_NUMBER } from 'src/common/base.common';

interface IMachineListHook {
    handleOnChangeSearchVal: (val: string) => void;
    modelList: string[];
    machineList: IMachineItem[];
    handleCreateMachine: () => void;
    handleDeleteMachine: (machineId: string) => void;
    handleUpdateMachine: (machine: IMachineItem) => void;
    createdMachineObject: IMachineItem;
    setCreatedMachineObject: React.Dispatch<React.SetStateAction<IMachineItem>>;
    deletedMachineObject: IMachineItem;
    setDeletedMachineObject: React.Dispatch<React.SetStateAction<IMachineItem>>;
    updatedMachineObject: IMachineItem;
    setUpdatedMachineObject: React.Dispatch<React.SetStateAction<IMachineItem>>;
    newestUpdateMachine: IMachineItem;
    setNewestUpdateMachine: React.Dispatch<React.SetStateAction<IMachineItem>>;
    machineCreationValidationError: string;
    machineInfoRows: () => ITableRow[];
    handleClickDeleteMachineLog: (machine: IMachineItem) => void;
    handleDeleteMachineLog: (params: IDeleteMachineLogItem) => void;
    deletedMachineLogObject: IDeleteMachineLogItem;
    setDeleteMachineLogObject: React.Dispatch<React.SetStateAction<IDeleteMachineLogItem>>;
}

const useMachineList = (): IMachineListHook => {
    const [appState, dispatch] = useAuthenticated();
    const [machineList, setMachineList] = useState<IMachineItem[]>([]);
    const [modelList, setModelList] = useState<string[]>([]);
    const originalMachineList = useRef<IMachineItem[]>([]);
    const originalMachineListForSearch = useRef<IMachineItem[]>([]);
    const [createdMachineObject, setCreatedMachineObject] = useState<IMachineItem>(MachineCommon.DEFAULT_MACHINE_ITEM);
    const [deletedMachineObject, setDeletedMachineObject] = useState<IMachineItem>(MachineCommon.DEFAULT_MACHINE_ITEM);
    const [updatedMachineObject, setUpdatedMachineObject] = useState<IMachineItem>(MachineCommon.DEFAULT_MACHINE_ITEM);
    const [newestUpdateMachine, setNewestUpdateMachine] = useState<IMachineItem>(MachineCommon.DEFAULT_MACHINE_ITEM);
    const [searchValue, setSearchValue] = useState<string>(BaseCommon.EMPTY_STRING);
    const [machineCreationValidationError, setMachineCreationValidationError] = useState<string>(BaseCommon.EMPTY_STRING);
    const [deletedMachineLogObject, setDeleteMachineLogObject] = useState<IDeleteMachineLogItem>(MachineCommon.DEFAULT_DELETE_MACHINE_LOG_ITEM);
    const getMachineLogInformation = async (): Promise<IMachineLogInfomation> => {
        let logResult = { machines: {} };
        try {
            logResult = await MachineCommon.QueryAgent.query<IMachineLogInfomation>({ path: MachineCommon.GET_LOG_INFOMATION_PATH });
        } catch (error: any) {
            dispatch(TypeCommon.setError(`${error?.data?.detail}`));
        }
        return logResult;
    };

    const getMachineList = useCallback(async () => {
        try {
            const machines = await MachineCommon.QueryAgent.query<IMachineListFromApi>({ path: MachineCommon.GET_MACHINE_LIST_PATH });
            const machineInformation = (await getMachineLogInformation()) as IMachineLogInfomation;
            if (machines) {
                const handledMachineData = MachineUtils.handleMachineData(machines.machines, machineInformation);
                originalMachineList.current = handledMachineData;
                setMachineList([...originalMachineList.current]);
            }
        } catch (error: any) {
            dispatch(TypeCommon.setError(`${error?.data?.detail}`));
        }
    }, []);

    const getModelList = useCallback(async () => {
        try {
            const models = await MachineCommon.QueryAgent.query<IModelListFromApi>({ path: MachineCommon.GET_MODEL_LIST_PATH });
            if (models) {
                setModelList(models?.models);
            }
        } catch (error: any) {
            dispatch(TypeCommon.setError(`${error?.data?.detail}`));
        }
    }, []);

    const handleUpdateMachine = async (machine: IMachineItem): Promise<boolean> => {
        dispatch(TypeCommon.setIsOpenBackdrop(true));

        const data: IUpdatedMachineForApi = {
            model: machine.model,
            time_zone: machine.timeZone.index,
            customer: machine.customer,
            name: machine.name,
        };
        try {
            const res = await MachineCommon.UpdateAgent.post<IUpdatedMachineFromApi>(MachineCommon.UPDATE_MACHINE_PATH, data, {
                pathParams: {
                    id: machine.id,
                },
            });
            await refreshScreen();
            const newestUpdateMachine: IMachineItem = machineList.find((machineItem: IMachineItem) => machineItem.id === machine.id) as IMachineItem;
            setNewestUpdateMachine(newestUpdateMachine);
            return true;
        } catch (error: any) {
            const renderError = format(MessageCommon.MESSAGE_ERROR_UPDATE_MACHINE, error?.data?.detail);
            dispatch(TypeCommon.setError(renderError));

            return false;
        } finally {
            dispatch(TypeCommon.setIsOpenUpdateMachineDialog(false));
            dispatch(TypeCommon.setIsOpenBackdrop(false));
        }
    };

    const handleCreateMachine = async () => {
        const createdMachineObjectTmp: IMachineItem = { ...createdMachineObject };
        let isUpdateFailed = false;

        const isValidMachine = validateMachine(createdMachineObjectTmp);
        if (!isValidMachine) {
            return;
        }
        dispatch(TypeCommon.setIsOpenBackdrop(true));

        const createdData: ICreatedMachineForApi = {
            id: createdMachineObjectTmp.id,
            model: createdMachineObjectTmp.model,
            time_zone: createdMachineObjectTmp.timeZone.index,
        };

        try {
            await MachineCommon.UpdateAgent.post<ICreatedMachineFromApi>(MachineCommon.CREATE_MACHINE_PATH, createdData, {});
            const isNeedUpdateMachine = createdMachineObjectTmp.name || createdMachineObjectTmp.customer;
            if (isNeedUpdateMachine) {
                isUpdateFailed = await handleUpdateMachine(createdMachineObjectTmp);
            }

            if (!isUpdateFailed) {
                await refreshScreen();
            }
            setNewestUpdateMachine(createdMachineObjectTmp);
        } catch (error: any) {
            const renderError = format(MessageCommon.MESSAGE_ERROR_CREATE_MACHINE, error?.data?.detail);
            dispatch(TypeCommon.setError(renderError));
        } finally {
            setCreatedMachineObject(MachineCommon.DEFAULT_MACHINE_ITEM);
            dispatch(TypeCommon.setIsOpenBackdrop(false));
            dispatch(TypeCommon.setIsOpenCreateMachineDialog(false));
        }
    };

    const handleDeleteMachine = async (machineId: string) => {
        dispatch(TypeCommon.setIsOpenBackdrop(true));
        try {
            const response = await MachineCommon.UpdateAgent.delete<IDeletedMachineFromApi>(MachineCommon.DELETE_MACHINE_PATH, {
                pathParams: {
                    id: machineId,
                },
            });
            await refreshScreen();
        } catch (error: any) {
            const renderError = format(MessageCommon.MESSAGE_ERROR_DELETE_MACHINE, error?.data?.detail);
            dispatch(TypeCommon.setError(renderError));
        } finally {
            dispatch(TypeCommon.setIsOpenDeleteConfirmationDialog(false));
            dispatch(TypeCommon.setIsOpenDeleteMachineDialog(false));
            dispatch(TypeCommon.setIsOpenBackdrop(false));
        }
    };

    const handleOnChangeSearchVal = (value: string): IMachineItem[] => {
        const lowerCaseVal = value.toLowerCase();
        setSearchValue(lowerCaseVal);
        const searchedList = originalMachineListForSearch.current.filter(machine => {
            return Object.keys(machine).some(key => {
                if (key === MachineCommon.TIME_ZONE_KEY_NAME) {
                    return MachineUtils.isFoundIndex(machine[key].name, lowerCaseVal);
                }
                return MachineUtils.isFoundIndex(machine[key], lowerCaseVal);
            });
        });
        setMachineList(searchedList);
        return searchedList;
    };

    const validateMachine = (machine: IMachineItem): boolean => {
        const isExistingMachine = originalMachineList.current.find(machineTmp => machineTmp?.id === machine?.id);
        if (isExistingMachine) {
            setMachineCreationValidationError(MessageCommon.MESSAGE_ERROR_EXISTING_MACHINE);
        }
        return !isExistingMachine;
    };
    const initData = async () => {
        dispatch(TypeCommon.setIsOpenBackdrop(true));
        await Promise.all([getModelList(), getMachineList()]);
        dispatch(TypeCommon.setIsOpenBackdrop(false));
    };

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        if (machineCreationValidationError) {
            setMachineCreationValidationError(BaseCommon.EMPTY_STRING);
        }
    }, [createdMachineObject?.id]);

    const handleOnClickDelete = (machine: IMachineItem) => {
        dispatch(TypeCommon.setIsOpenDeleteMachineDialog(true));
        setDeletedMachineObject(machine);
    };
    const handleClickUpdate = (machine: IMachineItem) => {
        dispatch(TypeCommon.setIsOpenUpdateMachineDialog(true));
        setUpdatedMachineObject(machine);
    };

    const getMachineAction = (machineInfo: IMachineItem): JSX.Element => {
        const hasLog: boolean =
            machineInfo.latestDatetime !== MachineCommon.DEFAULT_DATETIME || machineInfo.oldestDatetime !== MachineCommon.DEFAULT_DATETIME;
        const actionInfo: IMachineAction[] = [
            {
                index: 1,
                iconSrc: hasLog ? IC_LOG : IC_LOG_INACTIVE,
                clickEventHandler: hasLog ? () => handleClickDeleteMachineLog(machineInfo) : undefined,
                dataTestId: TestCommon.LOG_INFO_BUTTON_DATA_TEST_ID,
            },
            { index: 2, iconSrc: IC_EDIT, clickEventHandler: () => handleClickUpdate(machineInfo), dataTestId: TestCommon.EDIT_BUTTON_DATA_TEST_ID },
            {
                index: 3,
                iconSrc: IC_DELETE,
                clickEventHandler: () => handleOnClickDelete(machineInfo),
                dataTestId: TestCommon.DELETE_BUTTON_DATA_TEST_ID,
            },
        ];
        return (
            <div style={{ ...styles.machineActions }}>
                {actionInfo.map((action: IMachineAction) => (
                    <div
                        key={action.index}
                        role="button"
                        onClick={action?.clickEventHandler}
                        style={{ ...styles.machineAction, cursor: action.iconSrc === IC_LOG_INACTIVE ? EMPTY_STRING : CURSOR_POINTER }}
                        data-testid={action.dataTestId}
                    >
                        <img src={action.iconSrc} />
                    </div>
                ))}
            </div>
        );
    };

    const machineInfoRows = (): ITableRow[] => {
        return machineList.map((machine: IMachineItem) => {
            const row: ITableRow = {
                key: machine.id,
                id: machine.id,
                action: getMachineAction(machine),
                timeZone: machine.timeZone.name,
                oldestDatetime: machine.oldestDatetime,
                latestDatetime: machine.latestDatetime,
                model: machine.model,
                name: machine.name,
                customer: machine.customer,
            };
            return row;
        });
    };

    const handleClickDeleteMachineLog = (machine: IMachineItem) => {
        dispatch(TypeCommon.setIsOpenDeleteMachineLogDialog(true));

        const params: IDeleteMachineLogItem = {
            id: machine.id,
            timeZone: machine.timeZone.index,
            mode: BaseCommon.DELETE_MODE_BEFORE,
        };
        setDeleteMachineLogObject(params);
    };

    const handleDeleteMachineLog = async (params: IDeleteMachineLogItem) => {
        dispatch(TypeCommon.setIsOpenBackdrop(true));
        try {
            const { id, timeZone, mode, dateTime } = params;
            const response = await MachineCommon.DeleteLogAgent.delete<IDeletedMachineLogFromApi>(MachineCommon.DELETE_MACHINE_LOG_PATH, {
                pathParams: {
                    machine_id: id,
                },
                params: {
                    delete_new: mode === BaseCommon.DELETE_MODE_BEFORE ? mode : undefined,
                    tz: timeZone,
                    datetime: dateTime,
                },
            });
            await refreshScreen();
            if (response.affectedRows.total > ZERO_NUMBER) {
                const machine: IMachineItem = machineList.find((machine: IMachineItem) => machine.id === id) as IMachineItem;
                setNewestUpdateMachine(machine);
            }
        } catch (error: any) {
            const renderError = format(MessageCommon.MESSAGE_ERROR_DELETE_LOG_MACHINE, error?.data?.detail);
            dispatch(TypeCommon.setError(renderError));
        } finally {
            dispatch(TypeCommon.setIsOpenDeleteMachineLogConfirmationDialog(false));
            dispatch(TypeCommon.setIsOpenDeleteMachineLogDialog(false));
            dispatch(TypeCommon.setIsOpenBackdrop(false));
        }
    };

    const refreshScreen = async () => {
        await getMachineList();
        if (searchValue) {
            handleOnChangeSearchVal(searchValue);
        }
    };
    return {
        handleOnChangeSearchVal,
        modelList,
        machineList,
        handleCreateMachine,
        handleDeleteMachine,
        handleUpdateMachine,
        createdMachineObject,
        setCreatedMachineObject,
        deletedMachineObject,
        setDeletedMachineObject,
        updatedMachineObject,
        setUpdatedMachineObject,
        newestUpdateMachine,
        setNewestUpdateMachine,
        machineCreationValidationError,
        machineInfoRows,
        handleClickDeleteMachineLog,
        setDeleteMachineLogObject,
        deletedMachineLogObject,
        handleDeleteMachineLog,
    };
};

export default useMachineList;
